import revenueRange from '../../../constants/revenueRanges'

export const specificSteps = {
    'crim-transport': [
        ['impairedBy'],
        ['hasCourtDate', 'courtDate'],
        ['hasCriminalFile'],
        ['moreDetails'],
    ],
    'family-family': [['kids'], ['judicialized'], ['amicable']],
}
export default {
    admin: {
        procedureStarted: {
            label: `Procédures instituées auprès d'un tribunal?`,
            fieldType: 'yesNoDontKnow',
        },
        procedureStartedDt: {
            adminLabel: `Date d'audience`,
            label: 'Avez-vous une date d’audience? ',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'procedureStarted',
                toBe: 'yes',
            },
        },
        injuries: {
            label: 'Est-ce que votre dossier implique une ou des blessures corporelles?',
            adminLabel: `Blessures corporelles?`,
            fieldType: 'yesNoDontKnow',
        },
        injuriesDesc: {
            adminLabel: `Descriptions des blessures`,
            label: 'Pouvez-vous décrire la ou les blessures?,',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        employmentPeril: {
            label: 'Est-ce que cette blessure vous empêche ou vous empêchera de poursuivre votre emploi?',
            adminLabel: 'Emploi en péril?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        job: {
            label: 'Quel est ou était votre emploi?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            adminLabel: 'Emploi',
        },
    },
    business: {
        businessRepInd: {
            adminLabel: `Représente une entreprise?`,
            label: `Représentez-vous une entreprise?`,
            fieldType: 'yesNoDontKnow',
        },
        activitySector: {
            adminLabel: `Secteur d'activité`,
            label: `Quel est le secteur d'activité de l'entreprise`,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
        },
        numEmployees: {
            adminLabel: `Nombre d'employés`,
            label: `Combien d’employé(s) emploie l'entreprise?`,
            dependsOn: {
                fieldName: 'businessRepInd',
                toBe: 'yes',
            },
            fieldType: 'select',
            options: [
                {
                    name: 'none',
                    label: 'Aucun',
                },
                {
                    name: '1',
                    label: '1 à 5',
                },
                {
                    name: '2',
                    label: '6 à 15',
                },
                {
                    name: '3',
                    label: '16 à 25',
                },
                {
                    name: '4',
                    label: '26 à 50',
                },
                {
                    name: '5',
                    label: '50 et plus',
                },
            ],
        },
        moneyInd: {
            adminLabel: `Implique de l'argent`,
            label: `Est-ce que votre situation implique un montant d'argent`,
            fieldType: 'yesNoDontKnow',
        },
        moneyAmt: {
            adminLabel: `Montant d'argent`,
            label: 'Montant impliqué',
            dependsOn: {
                fieldName: 'moneyInd',
                toBe: 'yes',
            },
        },
        needsAccountant: {
            adminLabel: `Veut un comptable`,
            label: `Souhaiteriez-vous également être mis en relation avec un comptable d’affaires?`,
            fieldType: `yesNo`,
        },
    },
    criminal: {
        hasCourtDate: {
            label: 'Avez-vous une date de cour?',
            fieldType: 'yesNoDontKnow',
        },
        courtDate: {
            label: 'Date de cour',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
        },
        hasCriminalFile: {
            label: 'Avez-vous déjà un dossier criminel?',
            fieldType: 'yesNoDontKnow',
        },
        employementPeril: {
            label: 'Est-ce qu’être reconnu coupable de l’infraction pourrait mettre en péril votre emploi?',
            fieldType: 'yesNoDontKnow',
        },
    },
    'crim-transport': {
        impairedBy: {
            label: 'De quel type de facultées affaiblies êtes-vous accusé?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'alcohol',
                    label: 'Alcool',
                },
                {
                    value: 'drugs',
                    label: 'Drogue',
                },
                {
                    value: 'both',
                    label: 'Alcool et Drogue',
                },
            ],
        },
        hasCourtDate: {
            label: 'Avez-vous une date de cour?',
            fieldType: 'yesNoDontKnow',
        },
        courtDate: {
            label: 'Date de cour',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'hasCourtDate',
                toBe: 'yes',
            },
        },
        hasCriminalFile: {
            label: 'Est-ce votre première offense (si vous avez déjà un dossier criminel, répondez « non »)?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'yes',
                    label: 'Oui',
                },
                {
                    value: 'no',
                    label: 'Non',
                },
                {
                    value: 'dontWantToAnswer',
                    label: 'Je préfère ne pas répondre',
                },
            ],
        },
        moreDetails: {
            label: 'Souhaitez-vous nous fournir d’autres détails que vous jugez importants? ',
        },
        revenueRange: {
            label: 'Quelle situation représente le mieux votre revenu annuel?',
            adminLabel: 'Revenu annuel',
            fieldType: 'select',
            options: revenueRange.map((i, r) => ({
                name: r,
                label: i,
            })),
        },
    },
    rights: {
        procedureStarted: {
            label: `Procédures instituées auprès d'un tribunal?`,
            fieldType: 'yesNoDontKnow',
        },
        procedureStartedDt: {
            adminLabel: `Date d'audience`,
            label: 'Avez-vous une date d’audience? ',
            fieldType: 'date',
            dependsOn: {
                fieldName: 'procedureStarted',
                toBe: 'yes',
            },
        },
    },

    bankruptcy: {
        personType: {
            label: 'Vous êtes un:',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'person',
                    label: 'Particulier',
                },
                {
                    value: 'enterprise',
                    label: 'Entreprise',
                },
            ],
        },
    },
    family: {
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        needsRealEstateBroker: {
            adminLabel: `Veut un courtier immobillier`,
            label: `Si le patrimoine familial implique un ou des immeubles, souhaiteriez-vous également être mis en relation avec un courtier immobilier?`,
            fieldType: `yesNo`,
        },
        needsNotary: {
            adminLabel: 'Veut un notaire',
            label: `En cas de séparation ou de divorce, souhaiteriez-vous être mis en relation avec un notaire pour mettre à jour ou modifier votre testament?`,
            fieldType: 'yesNo',
        },
    },
    'family-family': {
        kids: {
            label: 'Est-ce que votre dossier implique la garde d’un ou de plusieurs enfants?',
            fieldType: 'yesNo',
        },
        judicialized: {
            label: 'Est-ce que votre dossier est judiciarisé (une mise en demeure ou autre procédure a déjà été reçue ou transmise)?',
            adminLabel: 'Dossier judiciarisé',
            fieldType: 'yesNoDontKnow',
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
    },
    fiscal: {
        personType: {
            label: 'Vous êtes un:',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'person',
                    label: 'Particulier',
                },
                {
                    value: 'enterprise',
                    label: 'Entreprise',
                },
            ],
        },
    },
    immigration: {
        alreadyInCanada: {
            label: 'Êtes-vous déjà au Canada?',
            fieldType: 'yesNo',
        },
        country: {
            label: 'Quel est votre pays de citoyenneté',
        },
        diploma: {
            label: 'Quel est votre niveau de scolarité? (dernier diplôme)',
        },
        studentOrWorker: {
            label: 'Êtes-vous étudiant ou travailleur?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'student',
                    label: 'Étudiant',
                },
                {
                    value: 'worker',
                    label: 'Travailleur',
                },
            ],
        },
        currentDiploma: {
            label: 'Quel est votre programme d’étude?',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'student',
            },
        },
        acceptedStudent: {
            label: 'Avez-vous déjà été accepté dans un établissement scolaire au Canada?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'student',
            },
        },
        work: {
            label: 'Quel travail (métier ou profession) exercez-vous et depuis quand?',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'worker',
            },
        },
        acceptedWorker: {
            label: 'Avez-vous déjà accepté une offre d’emploi au Canada?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'studentOrWorker',
                toBe: 'worker',
            },
        },
        netWorth: {
            label: 'Quelle est votre valeur nette personnelle en dollars Canadiens (certains programmes l’exigent)?',
            fieldType: 'select',
            options: [
                {
                    name: '10k',
                    label: 'Moins de 10 000 $',
                },
                {
                    name: '10-25k',
                    label: 'Entre 10 000 $ et 25 000 $',
                },
                {
                    name: '25-100k',
                    label: 'Entre 25 000 $ et 100 000 $',
                },
                {
                    name: '100-500k',
                    label: 'Entre 100 000 $ et 500 000 $',
                },
                {
                    name: '500-1000k',
                    label: 'Entre 500 000 $ et 1 000 000 $',
                },
                {
                    name: '1000-2000k',
                    label: 'Entre 1 000 000 $ et 2 000 000 $',
                },
                {
                    name: '2000k',
                    label: 'Plus de 2 000 000 $',
                },
            ],
        },
    },
    realestate: {
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        amount: {
            label: 'Si vous pouviez accorder une valeur monétaire à votre litige, vous l’évalueriez à combien? Il peut s’agir du montant de la réclamation, s’il y en a un.',
            adminLabel: 'Montant',
        },
    },
    responsability: {
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        injuries: {
            label: 'Est-ce que votre dossier implique une ou des blessures corporelles?',
            adminLabel: `Blessures corporelles?`,
            fieldType: 'yesNoDontKnow',
        },
        injuriesDesc: {
            adminLabel: `Descriptions des blessures`,
            label: 'Pouvez-vous décrire la ou les blessures?,',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        employmentPeril: {
            label: 'Est-ce que cette blessure vous empêche ou vous empêchera de poursuivre votre emploi?',
            adminLabel: 'Emploi en péril?',
            fieldType: 'yesNoDontKnow',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
        },
        job: {
            label: 'Quel est ou était votre emploi?',
            dependsOn: {
                fieldName: 'injuries',
                toBe: 'yes',
            },
            adminLabel: 'Emploi',
        },
    },
    succession: {
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        amount: {
            label: 'Quelle est la valeur estimée de la succession?',
            adminLabel: 'Montant',
        },

        needsRealEstateBroker: {
            adminLabel: `Veut un courtier immobillier`,
            label: `Si la succession comprend un ou des immeubles, souhaiteriez-vous également être mis en relation avec un courtier immobilier?`,
            fieldType: `yesNo`,
        },
        needsAccountant: {
            adminLabel: `Veut un comptable`,
            label: `Aux fins du règlement de la succession ou de sa reddition de compte, souhaiteriez-vous également être mis en relation avec un comptable?`,
            fieldType: `yesNo`,
        },
    },
    work: {
        employeeType: {
            label: 'Êtes-vous : employé ou employeur?',
            fieldType: 'buttonRow',
            options: [
                {
                    value: 'employee',
                    label: 'Employé',
                },
                {
                    value: 'employer',
                    label: 'Employeur',
                },
            ],
        },
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        howLong: {
            label: 'Depuis combien de temps êtes-vous à l’emploi de votre employeur?',
            fieldType: 'select',
            options: [
                {
                    name: '0',
                    label: 'Moins de 3 mois',
                },
                {
                    name: '1',
                    label: '3 mois à 1 an',
                },
                {
                    name: '2',
                    label: '1 à 2 ans',
                },
                {
                    name: '3',
                    label: '2 à 5 ans',
                },
                {
                    name: '4',
                    label: '5 à 10 ans',
                },
                {
                    name: '5',
                    label: '10 ans et plus',
                },
            ],
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
        },
        jobType: {
            label: `Quelle est votre fonction ou poste au sein de l'entreprise`,
            dependsOn: {
                fieldName: 'employeeType',
                toBe: 'employee',
            },
        },
    },
    civil: {
        amicable: {
            label: 'Êtes-vous confiant que votre dossier puisse se régler à l’amiable?',
            fieldType: 'yesNoDontKnow',
        },
        judicialized: {
            label: 'Est-ce que votre dossier est déjà judiciarisé (procédure déposée ou reçue)?',
            fieldType: 'yesNoDontKnow',
        },
        amount: {
            label: 'Si vous pouviez accorder une valeur monétaire à votre litige, vous l’évalueriez à combien? Il peut s’agir du montant de la réclamation, s’il y en a un.',
            adminLabel: 'Montant',
        },
    },
    survey: {
        recommended: {
            label: 'Recommanderiez-vous nos services de mise en relation avocats-clients?',
            fieldType: 'yesNo',
        },
        easyToUseRating: {
            label: 'Simplicité du Site et facilité d’utilisation',
            fieldType: 'rating',
        },
        speedRating: {
            label: 'Rapidité de la mise en relation',
            fieldType: 'rating',
        },
        firstCall: {
            label: 'Avez-vous eu un premier appel avec l’avocat que nous avons identifié pour vous?',
            fieldType: 'yesNo',
        },
        reasonNoFirstCall: {
            label: 'Pourquoi?',
            fieldType: 'select',
            dependsOn: {
                fieldName: 'firstCall',
                toBe: 'no',
            },
            options: [
                {
                    name: 'alreadySettled',
                    label: 'Mon dossier s’est réglé entretemps',
                },
                {
                    name: 'foundAnotherLawyer',
                    label: 'J’ai trouvé un autre avocat entretemps',
                },
                {
                    name: 'notFree',
                    label: 'Je pensais que les services de l’avocat serait gratuit',
                },
                {
                    name: 'neverCalled',
                    label: 'L’avocat n’a jamais communiqué avec moi',
                },
            ],
        },
        rightLawyer: {
            label: 'Considérez-vous que l’avocat que nous avons identifié était le bon pour vous?',
            fieldType: 'yesNoDontKnow',
        },
        reasonNotRightLawyer: {
            label: 'Pourquoi?',
            fieldType: 'select',
            dependsOn: {
                fieldName: 'rightLawyer',
                toBe: 'no',
            },
            options: [
                {
                    name: 'tooExpensive',
                    label: 'L’avocat coûtait trop cher',
                },
                {
                    name: 'notSpecializedEnough',
                    label: 'L’avocat n’était pas suffisamment spécialisé',
                },
                {
                    name: 'notEnoughExperience',
                    label: 'L’avocat manquait d’expérience',
                },
                {
                    name: 'tooFar',
                    label: 'L’avocat était trop loin géographiquement',
                },
                {
                    name: 'notAvailable',
                    label: 'L’avocat manquait de disponibilités',
                },
            ],
        },
        hiredLawyer: {
            label: 'Avez-vous confié votre dossier à l’avocat que nous avons identifié pour vous?',
            fieldType: 'buttonRow',
            options: [
                { value: 'yes', label: 'Oui' },
                { value: 'no', label: 'Non' },
                { value: 'dontKnow', label: 'Je suis toujours en réflexion' },
            ],
        },
        reasonNotHiredLawyer: {
            label: 'Pourquoi?',
            dependsOn: {
                fieldName: 'hiredLawyer',
                toBe: ['no', 'dontKnow'],
            },
        },
        happyWithLawyer: {
            label: 'Jusqu’à présent, êtes-vous satisfait de la prestation de service de l’avocat?',
            fieldType: 'yesNo',
            dependsOn: {
                fieldName: 'hiredLawyer',
                toBe: 'yes',
            },
        },
        newLawyer: {
            label: 'Souhaitez-vous que nous vous mettions en relation avec un nouvel avocat pour une deuxième soumission gratuite?',
            fieldType: 'yesNo',
            dependsOnOne: [
                {
                    fieldName: 'firstCall',
                    toBe: 'no',
                },
                {
                    fieldName: 'rightLawyer',
                    toBe: ['no', 'dontKnow'],
                },
                {
                    fieldName: 'hiredLawyer',
                    toBe: ['no', 'dontKnow'],
                },
                {
                    fieldName: 'firstCall',
                    toBe: 'no',
                },
            ],
        },
        comment: {
            label: 'Comment pourrions-nous améliorer notre service de mise en relation?',
        },
    },
}
