export default [
    '',
    'Moins de 51 000$',
    'Entre 51 000$ et 64 999$',
    'Entre 65 000$ et 84 999$',
    'Entre 85 000$ et 99 999$',
    'Entre 100 000$ et 149 999$',
    'Entre 150 000$ et 300 000$',
    'Plus de 300 000$',
]
