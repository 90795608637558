import React, { useState } from 'react'
import { Grid, Button } from '@material-ui/core'
import Rating from '@material-ui/lab/Rating'
import styled from 'styled-components'
import { post } from '../../services/request'
import allFields, { specificSteps } from './specificQuestions/fields'
import imgSrc from '../../../static/undraw_confirmation_2uy0.svg'
import CircularSpinner from '../../components/CircularSpinner'
import YesNoMaybe from '../../components/ButtonRow/yesNoMaybe'
import DatePicker from '../../components/DatePicker'
import TextField from '../../components/TextField'
import Select from '../../components/Select'
import { LeftSpan } from '../../components/Typo'
import ButtonRow from '../../components/ButtonRow'
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next'

const StyledRating = styled(Rating)`
    span {
        font-size: inherit;
    }
`

const SubmitButton = styled(Button)`
    margin-left: 0;
    margin-top: 20px;
    font-size: 20px;
    span {
        font-size: inherit;
    }
`

const MoreInfoForm = ({
    lawyerType,
    uuid,
    customImg,
    skip,
    submitCallback,
    withSteps,
    activeStep,
    handleNext,
    setSpecificQuestions,
}) => {
    const [fields, setFields] = useState({})
    const [updated, setUpdated] = useState(false)
    const [loading, setLoading] = useState(false)

    const saveFieldState = (name, value, valid) => {
        setFields({
            ...fields,
            [name]: {
                value: value,
                valid: true, // change once we need a validator
            },
        })
        if (withSteps) {
            const currStep = specificSteps[lawyerType][activeStep]
            const lastFieldInStep = currStep[currStep.length - 1]
            setSpecificQuestions(prepareFieldsForSubmit())
            if (
                name === lastFieldInStep &&
                allFields[lawyerType][name].fieldType &&
                allFields[lawyerType][name].fieldType !== 'text'
            ) {
                handleNext()
            }
        }
    }

    const prepareFieldsForSubmit = () => {
        const prepFields = {}
        Object.keys(fields).forEach((f) => {
            prepFields[f] = fields[f].value
        })
        return prepFields
    }

    const submit = () => {
        const body = prepareFieldsForSubmit()
        setLoading(true)
        const url =
            lawyerType === 'survey'
                ? `/reviews/${uuid}`
                : `/meetings/${uuid}/additional-questions`
        post(url, body)
            .then((res) => {
                if (res.ok) {
                    setUpdated(true)
                    if (submitCallback) {
                        submitCallback(body)
                    }
                }
                setLoading(false)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <>
            {(updated || skip) && (
                <h2>
                    <Trans>Merci</Trans>!
                </h2>
            )}
            {!updated && !skip && allFields[lawyerType] && (
                <>
                    {lawyerType !== 'survey' && !withSteps && (
                        <p>
                            <strong>
                                <Trans>
                                    Répondez à ces questions afin que nous
                                    assignions l'avocat le mieux adapté à vos
                                    besoins:
                                </Trans>
                            </strong>
                        </p>
                    )}
                    <SpecificForm
                        lawyerType={lawyerType}
                        saveFieldState={saveFieldState}
                        withSteps={withSteps}
                        activeStep={activeStep}
                        {...fields}
                    />
                    {!withSteps ? (
                        <SubmitButton
                            variant="contained"
                            color="secondary"
                            onClick={submit}
                            disabled={loading}
                        >
                            <Trans>Soumettre</Trans>
                            {loading && (
                                <CircularSpinner
                                    style={{ width: '24px', height: '24px' }}
                                />
                            )}
                        </SubmitButton>
                    ) : null}
                </>
            )}
        </>
    )
}

const validateDependency = (dependeeValue, valueToBe) => {
    if (dependeeValue === valueToBe) {
        return true
    }
    if (Array.isArray(valueToBe) && valueToBe.indexOf(dependeeValue) > -1) {
        return true
    }
    return false
}

const checkDependency = (fieldDef, fieldsState = {}) => {
    if (!fieldDef.dependsOn && !fieldDef.dependsOnOne) {
        return true
    }
    if (fieldDef.dependsOn) {
        const dependee = fieldsState[fieldDef.dependsOn.fieldName]
        if (
            dependee &&
            validateDependency(dependee.value, fieldDef.dependsOn.toBe)
        ) {
            return true
        }
    }

    if (fieldDef.dependsOnOne) {
        for (var i = 0; i < fieldDef.dependsOnOne.length; i++) {
            if (
                fieldsState[fieldDef.dependsOnOne[i].fieldName] &&
                validateDependency(
                    fieldsState[fieldDef.dependsOnOne[i].fieldName].value,
                    fieldDef.dependsOnOne[i].toBe
                )
            ) {
                return true
            }
        }
    }
    return false
}

const SpecificForm = ({
    lawyerType,
    saveFieldState,
    withSteps,
    activeStep,
    endCallback = () => {},
    ...fieldsState
}) => {
    const formDefs = allFields[lawyerType]
    const { t } = useTranslation()

    if (formDefs) {
        if (withSteps) {
            if (activeStep >= specificSteps[lawyerType]?.length) {
                endCallback()
                return null
            }
            return (
                <Grid container spacing={2} style={{ maxWidth: '500px' }}>
                    {specificSteps[lawyerType][activeStep].map((f, i) => {
                        if (!checkDependency(formDefs[f], fieldsState)) {
                            return null
                        }

                        return (
                            <Grid item xs={12} key={formDefs[f].label}>
                                {withSteps && i === 0 ? (
                                    <h2
                                        style={{
                                            textAlign: 'left',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        {t(formDefs[f].label)}
                                    </h2>
                                ) : (
                                    <LeftSpan>
                                        <strong>{t(formDefs[f].label)}</strong>
                                    </LeftSpan>
                                )}

                                <div style={{ maxWidth: '100%' }}>
                                    <Field
                                        name={f}
                                        fieldDef={formDefs[f]}
                                        fieldState={fieldsState[f]}
                                        saveFieldState={saveFieldState}
                                    />
                                </div>
                            </Grid>
                        )
                    })}
                </Grid>
            )
        }
        return (
            <Grid container spacing={2}>
                {Object.keys(formDefs).map((f) => {
                    if (!checkDependency(formDefs[f], fieldsState)) {
                        return null
                    }
                    return (
                        <Grid item xs={12} key={formDefs[f].label}>
                            <LeftSpan>{t(formDefs[f].label)}</LeftSpan>
                            <Field
                                name={f}
                                fieldDef={formDefs[f]}
                                fieldState={fieldsState[f]}
                                saveFieldState={saveFieldState}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        )
    }
    return null
}

export const SpecificStepForm = {}

export const Field = ({ name, fieldDef, fieldState, saveFieldState }) => {
    const fieldProps = {
        name,
        saveFieldState,
        value: fieldState?.value,
        label: fieldDef.shortLabel,
        id: name,
        onChange: saveFieldState,
    }
    switch (fieldDef.fieldType) {
        case 'yesNoDontKnow':
            return <YesNoMaybe {...fieldProps} />
        case 'yesNo':
            return (
                <ButtonRow
                    {...fieldProps}
                    options={[
                        { value: 'yes', label: 'Oui' },
                        { value: 'no', label: 'Non' },
                    ]}
                />
            )
        case 'date':
            return (
                <DatePicker
                    {...fieldProps}
                    onChange={(date) => saveFieldState(name, date, true)}
                />
            )
        case 'select':
            return (
                <Select
                    {...fieldProps}
                    onChange={(v) => saveFieldState(name, v, true)}
                    options={fieldDef.options}
                />
            )
        case 'buttonRow':
            return <ButtonRow {...fieldProps} options={fieldDef.options} />
        case 'rating':
            return (
                <StyledRating
                    size={'large'}
                    {...fieldProps}
                    onChange={(_, value) => saveFieldState(name, value, true)}
                />
            )
        default:
            return <TextField {...fieldProps} fullWidth={true} />
    }
    return null
}

export default MoreInfoForm
